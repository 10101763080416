.container-headerparkspring{
    display: flex;
    width: 100%;
    box-shadow: 1px 1px 5px 1px rgb(93, 93, 93);
    .logoparkspring{
        width: 100%;
        padding: 10px;
        margin-left: 50px;
        @media (max-width: 768px) {
        width: 100%;
        margin-left: 75px;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-bottom: -25px;

        }
        .gambarparkspring{
            width: 25%;
        @media (max-width: 768px) {
            //margin-top: 10px;
            width: 75%;
            justify-content: center;
            align-items: center;
            align-content: center;
            }    
        }
    }
    .container-buttonwa {
        @media (max-width: 768px) {
            visibility: hidden;
            width: 0%;
        }
        width: 100%;
        align-items: center;
        justify-content: right;
        display: flex;
        .button{
            margin-right: 10%;
            display: inline-block;
          padding: 15px 50px;
          color: white;
          margin-top: 30px;
          background-color: #287d50;
          border: 1px solid #287d50; /* Add this line for black border */
          border-color: #287d50;
          border-radius: 25px;
          font-size: 16px;
          font-weight: light;
          cursor: pointer;
          display: inline-block;
          transition: background-color 0.3s ease, color 0.3s ease;
          @media (max-width: 768px) {
            width: 70%;
          }
          &:hover {
            background-color: white;
            color: #287d50;
        }
    }
    }
}