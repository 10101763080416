.background-container {
    background-image: url("../../../media//Header\ Park\ Spring.png"); /* Replace with your desktop image URL */
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
    @media (max-width: 768px) {
      /* Adjust breakpoint as needed */
      background-image: url("../../../media/header\ mobile\ park\ spring.png");
      height: 70vh;
    
    }
  }