.container-lokasi {
    width: 100%;
    z-index: -10;
    background-size: cover;
    background-position: center;
    display: flex;
    @media (max-width: 768px){
        flex-direction: column;
        padding-bottom: 15px;
    }

    .lokasi {
        width: 100%;
        margin-top: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media (max-width: 768px){}
        .judul-hardsell1{
            @media (max-width: 768px){  }
            
        }
        .lokasi-tallasa{
            width: 70%;
            margin-bottom: 10%;
            @media (max-width: 768px){
                width: 80%;
                border-radius: 5px;   
            } 
        }
    }
    .container-judul {
        
        width: 95%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .a {
            z-index: -10;
            .judul-lokasi{
                @media (max-width: 768px){}
        
            h1{
                font-family: 'Poppins', sans-serif;
                font-size: 70px;
                font-weight: 500;
                color: #D6B17A;
                text-align: left;
                margin-left: 15%;
                @media (max-width: 768px){
                    font-size: 40px;
                    text-align: left;
                    align-items: left;
                    margin-top: 20%;
                }
            }
            h2{
                font-family: 'Poppins', sans-serif;
                font-size: 70px;
                font-weight: 500;
                margin-top: -75px;
                color: #D6B17A;
                text-align: left;
                margin-left: 15%;
                @media (max-width: 768px){
                    width: 100%;
                    font-size: 40px;
                    text-align: left;
                    align-items: left;
                    margin-top: -8%;
                }
            }
            h3{
                font-family: 'Poppins', sans-serif;
                font-size: 70px;
                font-weight: 500;
                margin-top: -75px;
                color: #D6B17A;
                text-align: left;
                margin-left: 15%;
                @media (max-width: 768px){
                    font-size: 40px;
                    text-align: left;
                    align-items: left;
                    margin-top: -8%;
                }
            }
            .deskripsi {
                margin-top: -5%;
            }
            h4{
                font-family: 'amiko', sans-serif;
                font-size: 20px;
                font-weight: 200;
                text-align: left;
                margin-left: 15%;
                color: #373737;
                @media (max-width: 768px){
                    font-size: 20px;
                    text-align: left;
                    align-items: left;
                }
            }
            h5{
                font-family: 'amiko', sans-serif;
                font-size: 20px;
                font-weight: 200;
                text-align: left;
                margin-left: 15%;
                margin-top: -2%;
                color: #373737;
                @media (max-width: 768px){
                    font-size: 20px;
                    text-align: left;
                    align-items: left;
                    margin-top: -5%;
                }
            }
            h6{
                font-family: 'amiko', sans-serif;
                font-size: 20px;
                font-weight: 200;
                text-align: left;
                margin-left: 15%;
                margin-top: -3%;
                color: #373737;
                @media (max-width: 768px){
                    font-size: 20px;
                    text-align: left;
                    align-items: left;
                    margin-top: -5%;
                }
            }
        }
        
        }
        .button-penawaran{
            display: flex;
            justify-content: center; 
            align-items: center;
            .buttonpenawaran{
            color: rgb(255, 255, 255);
            font-weight: 500;
            margin-top: 30px;
            background-color: #244D31;
            padding: 15px 100px;
            border: 1px solid #244D31; /* Add this line for black border */
            border-color: #244D31;
            border-radius: 25px;
            font-size: 16px;
            font-weight: light;
            cursor: pointer;
           // display: inline-block;
            transition: background-color 0.3s ease, color 0.3s ease;
            @media (max-width: 768px) {
            margin-bottom: 15%;
            margin-top: -5%;
            cursor: pointer;
            }
            &:hover {
            background-color: rgb(255, 255, 255);
            color: #244D31;
                }
            }
        } 
    }
    .lokasi-parkspring{
        border: 0;
        border-radius: 15px;
        height: 80%;
        @media (max-width: 768px) {
            width: 90%;
            height: 90%;
        }

    }
}